<template>
  <div class="third-bar">
    <div class="container">
      <div class="class">
        <h3 class="fist-title primary-title">
          {{ $t("instructionPage.ThirdBar.classification.title") }}
        </h3>
        <div class="primary-text">
          <p>
            {{ $t("instructionPage.ThirdBar.classification.list.first") }}
          </p>

          <p>{{ $t("instructionPage.ThirdBar.classification.list.second") }}</p>
          <p>
            {{ $t("instructionPage.ThirdBar.classification.list.third") }}
          </p>
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="container">
        <div class="wrap">
          <h3 class="primary-title">
            {{ $t("instructionPage.ThirdBar.photos.title") }}
          </h3>
          <div class="right-container primary-text">
            <ul>
              <li>
                <p>
                  {{ $t("instructionPage.ThirdBar.photos.list.first") }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t("instructionPage.ThirdBar.photos.list.second") }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t("instructionPage.ThirdBar.photos.list.third") }}
                </p>
              </li>
            </ul>
            <p class="blue">
              {{ $t("instructionPage.ThirdBar.photos.list.fourth") }}
            </p>
          </div>
          <div class="center-container center">
            <img :src="img.first" alt="fotos" />
          </div>

          <div class="right-container primary-text">
            <h3 class="primary-title">
              {{ $t("instructionPage.ThirdBar.passport.title") }}
            </h3>
            <p>
              {{ $t("instructionPage.ThirdBar.passport.list.first") }}
            </p>
          </div>
          <div class="center-container center">
            <img :src="img.second" alt="fotos" />
          </div>
          <div class="right-container primary-text">
            <h3 class="primary-title">
              {{ $t("addInstructionPage.pinflPassport.title") }}
            </h3>
            <p>
              {{ $t("addInstructionPage.pinflPassport.list") }}
            </p>
          </div>
          <div class="center-container center">
            <img class="small-img" :src="img.pinflPassport" alt="fotos" />
          </div>

          <div class="right-container primary-text">
            <h3 class="primary-title">
              {{ $t("instructionPage.ThirdBar.idCard.title") }}
            </h3>
            <p>
              {{ $t("instructionPage.ThirdBar.idCard.list.first") }}
            </p>
          </div>
          <div class="center-container center">
            <img :src="img.third" alt="fotos" />
          </div>

          <div class="right-container primary-text">
            <h3 class="primary-title">
              {{ $t("addInstructionPage.pinflID.title") }}
            </h3>
            <p>
              {{ $t("addInstructionPage.pinflID.list") }}
            </p>
          </div>
          <div class="center-container center">
            <img class="small-img" :src="img.pinflID" alt="fotos" />
          </div>

          <div class="right-container primary-text">
            <h3 class="primary-title">
              {{ $t("instructionPage.ThirdBar.bornCard.title") }}
            </h3>

            <p>
              {{ $t("instructionPage.ThirdBar.bornCard.list.first") }}
            </p>
          </div>
          <div class="center-container center">
            <img :src="img.fourth" alt="fotos" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  background: transparent;
}

.center {
  display: flex;
  justify-content: center;
}

.third-bar {
  width: 100%;
  padding-top: 4.75rem;

  img {
    max-width: 50rem;
    background-position: center;
    margin-bottom: 5.63rem;
    &.small-img {
      max-width: 27.5rem;
    }
  }
}
.blue {
  color: var(--primary-blue);
}

.class {
  padding-bottom: 4.75rem;
}

.wrap {
  padding-top: 4.75rem;
}

.third-bar {
  h3 {
    margin-bottom: 1.4375rem;
  }
  h4 {
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 1em;
  }
  p {
    max-width: 55rem;
  }
  .spacer {
    height: 8.125rem;
  }

  .bg {
    background: var(--bg-light-grey);
  }
}
.center-container {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .third-bar {
    padding-top: 2.25rem;

    img {
      margin-bottom: 2.25rem;
      max-width: 28.125rem;
    }
  }
}
@media screen and (max-width: 525px) {
  .third-bar {
    padding-top: 1.5rem;

    img {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
