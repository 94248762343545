<template>
  <div class="main">
    <div class="container">
      <h1 class="main-title primary-title" :class="{ korean: false }">
        {{ $t("instructionPage.title") }}
      </h1>

      <ul ref="list" class="list">
        <li class="list__item activ-always">
          <h3 class="number primary-text">01</h3>
          <span class="text primary-text">{{
            $t("instructionPage.steps.first")
          }}</span>
        </li>
        <li class="list__item">
          <h3 class="number primary-text">02</h3>
          <span class="text primary-text">{{
            $t("instructionPage.steps.second")
          }}</span>
        </li>
        <li class="list__item">
          <h3 class="number primary-text">03</h3>
          <span class="text primary-text">
            {{ $t("instructionPage.steps.oneGadget") }}</span
          >
        </li>
        <li class="list__item">
          <h3 class="number primary-text">04</h3>
          <span class="text primary-text">
            {{ $t("instructionPage.steps.third") }}</span
          >
        </li>
        <li class="list__item">
          <h3 class="number primary-text">05</h3>
          <span class="text primary-text">{{
            $t("instructionPage.steps.fourth")
          }}</span>
        </li>

        <li class="list__item">
          <h3 class="number primary-text">06</h3>
          <p class="text primary-text">
            {{ $t("instructionPage.steps.fivth") }}
          </p>

          <p class="text primary-text">
            <a
              class="link primary-text"
              href="https://topiktashkec.uz"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://topiktashkec.uz</a
            >
            <span> {{ $t("instructionPage.steps.sixth") }}</span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.main {
  &-title {
    margin-bottom: 2.9375rem;
  }
  .list__item {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .number {
      font-weight: 700;
      margin-bottom: 0.75rem;
      color: var(--primary-blue);
    }
  }

  .link {
    color: var(--primary-blue);
    display: inline-block;
    margin-right: 0.4rem;
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 525px) {
  .main {
    &-title {
      margin-bottom: 1.125rem;
    }
    .list__item {
      margin-bottom: 1.375rem;
      .number {
        font-size: 0.875rem;
        margin-bottom: 0.375rem;
      }
    }
  }
}
</style>
