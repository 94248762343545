<template>
  <div class="instruction">
    <div v-scrollanimation>
      <MainBar class="MainBar main-padding" />
    </div>
    <div v-scrollanimation>
      <SecondBar class="SecondBar main-padding" />
    </div>
    <div v-scrollanimation>
      <ThirdBar :img="img" class="ThirdBar" />
    </div>

    <div v-scrollanimation>
      <FourthBar class="FourthBar main-padding" />
    </div>

    <div v-scrollanimation>
      <FiveBar class="FiveBar main-padding" />
    </div>
  </div>
</template>

<script>
import MainBar from "@/components/instruction/MainBar.vue";
import SecondBar from "@/components/instruction/SecondBar.vue";
import ThirdBar from "../components/instruction/ThirdBar.vue";
import FourthBar from "../components/instruction/FourthBar.vue";
import FiveBar from "../components/instruction/FiveBar.vue";
import { useLanguage } from "@/utils/language";

export default {
  components: {
    MainBar,
    SecondBar,
    ThirdBar,
    FourthBar,
    FiveBar,
  },
  setup() {
    const { i18n } = useLanguage();
    const locale = i18n.global.locale.value;
    const img = {
      first: require(`@/assets/instruction/${locale}_img1.png`),

      second: require(`@/assets/instruction/${locale}_img2.png`),

      third: require(`@/assets/instruction/${locale}_img3.png`),

      fourth: require(`@/assets/instruction/${locale}_img4.png`),

      pinflPassport: require(`@/assets/img/pinfl__${locale}__passport.png`),

      pinflID: require(`@/assets/img/pinfl__${locale}__id_doc.png`),
    };
    return { img };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  img {
    box-shadow: 0rem 0.5rem 1.75rem -0.375rem rgba(24, 39, 75, 0.12),
      0rem 1.125rem 5.5rem -0.25rem rgba(24, 39, 75, 0.14);
      border-radius: 1.5625rem;
  }

  .small-img {
    max-width: 26.875rem;
  }
}
.instruction {
  --gap: 25px;
  --max-width: 1500px;
  font-size: 16px;

  @media (max-width: 1500px) {
    --gap: 25px;
    --max-width: 90%;
    font-size: 14px;
  }
}

.main-padding {
  padding: 4.75rem 0;
}

@media (max-width: 767px) {
  .main-padding {
    padding: 2.25rem 0;
  }
}
/*
    This classes are for the directive. 
    For each element observed by our directive, the before-enter class is added.
  */
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s ease-out;
}
/* 
    If the element intersects with the viewport, the before-enter class is added.
  */
.enter {
  opacity: 1;
  transform: translateY(0px);
}

@media (max-width: 525px) {
  .main-padding {
    padding: 1.5rem 0;
  }
}
</style>
