<template>
  <div class="five-bar">
    <div class="container primary-text">
      <h3 class="primary-title">{{ $t("instructionPage.FiveBar.title") }}</h3>
      <p>
        {{ $t("instructionPage.FiveBar.list.first") }}
      </p>
      <p>
        {{ $t("instructionPage.FiveBar.list.second") }}
      </p>
      <p>
        {{ $t("instructionPage.FiveBar.list.third") }}
      </p>
      <p>
        {{ $t("instructionPage.FiveBar.list.fourth") }}
      </p>
      <p>
        {{ $t("instructionPage.FiveBar.list.fivth") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.svg {
  display: none;
}
.five-bar {
  background:var(--bg-light-grey);
  h3 {
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
</style>
