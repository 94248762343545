<template>
  <div class="fourth">
    <div class="container">
      <div class="row">
        <h3 class="primary-title">
          {{ $t("instructionPage.FourtBar.title") }}
        </h3>
        <h4 class="primary-text">
          {{ $t("instructionPage.FourtBar.login.title") }}
          <a
            href="https://topiktashkec.uz"
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </h4>
        <ul class="primary-text">
          <li>
            {{ $t("instructionPage.FourtBar.login.list.first") }}
          </li>
          <li>
            {{ $t("instructionPage.FourtBar.login.list.second") }}
          </li>
          <li>
            {{ $t("instructionPage.FourtBar.login.list.third") }}
          </li>
          <!-- <li>
          {{ $t("instructionPage.FourtBar.login.list.fourth") }}
        </li> -->
        </ul>
        <h4 class="primary-text">
          {{ $t("instructionPage.FourtBar.application.title") }}
        </h4>
        <ul class="primary-text">
          <li>
            {{ $t("instructionPage.FourtBar.application.list.first") }}
          </li>
          <li>
            {{ $t("instructionPage.FourtBar.application.list.second") }}
          </li>
          <li>
            {{ $t("instructionPage.FourtBar.application.list.third") }}
          </li>
          <li>
            {{ $t("instructionPage.FourtBar.application.list.fourth") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.fourth {
  ul {
    margin-bottom: 30px;
  }
  h4 {
    color: var(--primary-blue);
  }
  h3 {
    max-width: 46.875rem;
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 576px) {
}
</style>
